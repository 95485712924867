<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Tipe Suplier</h5>
            </template>
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-end">
                        <b-button class="" variant="pgiBtn" @click="$bvModal.show('m-create')">
                            <i class="fa fa-plus"></i> Tipe Suplier
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-table bordered :items="supllierTypeAll" :fields="fields" hover show-empty :busy="isBusy" responsive
                class="my-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data tipe suplier.
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Edit"
                            name="kategoriEditBtn" @click="openModalEdit(row.item.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </b-button>

                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="suplierEditBtn" @click="confirmHapus(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>

                    </div>
                </template>
            </b-table>
        </b-card>
        <b-modal id="m-create" centered title="Formulir Tipe Suplier" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form @submit.prevent="submitTipe">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Nama Tipe" for="nama-tipe">
                            <b-form-input :class="$v.form.type.$error ? 'is-invalid' : ''" v-model="form.type"
                                id="nama-tipe" type="text"></b-form-input>
                            <small v-if="!$v.form.type.required && $v.form.type.$dirty" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="text-right">
                    <b-button type="submit" variant="pgiBtn">
                        Simpan
                    </b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="m-edit" centered title="Formulir Tipe Suplier" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form @submit.prevent="submitEditTipe">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Nama Tipe" for="nama-tipe">
                            <b-form-input :class="$v.form.type.$error ? 'is-invalid' : ''" v-model="form.type"
                                id="nama-tipe" type="text"></b-form-input>
                            <small v-if="!$v.form.type.required && $v.form.type.$dirty" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="text-right">
                    <b-button type="submit" variant="pgiBtn">
                        Simpan
                    </b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../../config/api';
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: 'master-tipe-suplier',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            fields: [
                { key: "type", label: "Nama Tipe" },
                { key: "action", label: "Aksi" },
            ],
            supllierTypeAll: [],
            form: {
                type: null
            },
            idForEdit: null
        }
    },
    validations: {
        form: {
            type: {
                required,
            },
        },
    },
    methods: {
        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },
        async init() {
            this.initStatus = 0
            const types = await axiosClient({
                method: 'GET',
                url: 'supplier-type/all',
            })
            this.supllierTypeAll = types.data.data


            Promise.all([types]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },
        submitTipe() {
            this.$v.form.$touch();
            if (this.$v.form.$error) {
                return
            }
            this.confirmCreate();
        },
        async confirmCreate() {
            this.$swal({
                title: "Konfirmasi",
                html: "Apakah Anda yakin tipe suplier sudah sesuai?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "(Ya) Simpan",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$axios
                        .post("/api/supplier-type", this.form)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$bvModal.hide('m-create')
                                this.$helper.toastSucc(this, "Berhasil membuat tipe!")
                                this.toggleBusy()
                                this.init()
                            }
                        })
                        .catch((error) => {
                            this.$helper.toastErr(this, "Gagal membuat tipe!")
                        });
                }
            });
        },

        async openModalEdit(id) {
            const { data } = await axiosClient({
                method: 'GET',
                url: 'supplier-type/' + id,
            })
            if (data.code === 200) {
                this.form = { type: data.data.type }
                this.idForEdit = data.data.id
                this.$bvModal.show('m-edit')
            }
        },
        submitEditTipe() {
            this.$swal({
                title: "Konfirmasi",
                html: "Apakah Anda yakin tipe suplier sudah sesuai?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "(Ya) Simpan",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$axios
                        .post("/api/supplier-type/" + this.idForEdit, this.form)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$bvModal.hide('m-edit')
                                this.$helper.toastSucc(this, "Berhasil edit tipe!")
                                this.toggleBusy()
                                this.init()
                            }
                        })
                        .catch((error) => {
                            this.$helper.toastErr(this, error.response.message || "Gagal edit tipe!")
                        });
                }
            });
        },
        confirmHapus(id) {
            this.$swal({
                title: "Konfirmasi",
                html: "Apakah Anda yakin menghapus tipe?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "(Ya) Hapus",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$axios
                        .delete("/api/supplier-type/" + id)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$helper.toastSucc(this, "Berhasil menghapus tipe!")
                                this.toggleBusy()
                                this.init()
                            }
                        })
                        .catch((error) => {
                            this.$helper.toastErr(this, "Gagal menghapus tipe!")
                        });
                }
            });
        },
    },
    created() {
        this.init()
    }
}
</script>